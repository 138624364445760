import Vue from 'vue'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag";
import VueScrollTo from 'vue-scrollto'
import VueGtm from '@gtm-support/vue2-gtm';

Vue.config.productionTip = false

const isProd = process.env.NODE_ENV === 'production'

Vue.use(VueGtm, {
  id: 'GTM-KVRQ5MR',
  queryParams: {
    // Add url query string when load gtm.js with GTM ID (optional)
    // gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    // gtm_preview: 'env-4',
    // gtm_cookies_win: 'x'
  },
  enabled: isProd, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

Vue.use(VueGtag, {
  config: { id: "G-9MSWDPLBN9" },
  enabled: isProd
});

Sentry.init({
  Vue: Vue,
  dsn: 'https://44968465d8c14a259c78b5a675fad11e@o342868.ingest.sentry.io/5606242',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.VUE_APP_ENV
});
Vue.use(VueScrollTo, {})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
