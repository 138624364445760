<template>
  <div>
    <section class="py-4" style="margin-bottom:30px;" v-if="pec != null">
       <div class="container">
        <div class="row pb-4">
          <div class="col text-center">
            <h3>Prise en charge N°{{pec.num_dossier}}
            <p class="lead text-center" style="font-size: 16px;"><i>Suivi en temps-réel de votre prise en charge</i></p>
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-8 offset-lg-2">
            <b>Date d'entrée</b>: {{pec.date_entree}}
            <br>
            <b>Modèle</b>: {{pec.modele}}
            <br>
            <b>Etat initial</b>: <span v-html="formatText(pec.etat_initial)"></span>
            <br>
            <b>Description de la panne</b>: <span v-html="formatText(pec.panne)"></span>
            <br>
            <b>Solutions proposées</b>: <span v-html="formatText(pec.solution)"></span>
          </div>
        </div>

        <div class="row pt-5 pb-2">
          <div class="col-12 col-lg-8 offset-lg-2">
            <h4>Statuts</h4>
          </div>
        </div>

        <div class="row" v-for="(item, index) in pec.histo" :key="item.id">
          <div class="col-12 col-lg-8 offset-lg-2">
            <span :style="{ 'color': (item.status_id == 4) ? '#ac5c5c' : '', 'font-weight': (index == 0) ? 'bold' : '' }">{{item.status}} le {{item.begin_date}}</span>
            <!-- <div class="pl-3" v-if="item.note.length > 0"><i><span v-html="formatText(item.note)"></span></i></div> -->
          </div>
        </div>
       </div>
    </section>
  </div>
</template>

<script>
import { getSuivi } from '@/api/crm-api.js'

export default {
  components: {
  },
  props: {
    id: String
  },
  data () {
    return {
      pec: null
    }
  },
  methods: {
    formatText: function (text) {
      return text == null ? '' : text.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
  },
  mounted()
  {
      getSuivi(this.id).then((response) => {
          if(response.data === false)
            window.location.href='https://www.macinstore.fr'
          else
            this.pec = { ...response.data }
      })
  }
}
</script>

<style>
  .btn {
    border: 1px solid #333334;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 22px;
    line-height: 30px;
    color: #333334;
    border-radius: 6px;
  }

  .btn:hover {
    background-color: #EAE1D7;
    color: #333334!important;
    border-color: black!important;
  }

  .btn:focus {
    box-shadow: none;
  }

  .btn-alt {
    color: #FFFFFF;
    border: 1px solid #333334;
    background: #333334;
    border-radius: 5px;font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
  }

  .btn-alt:hover {
    background-color: #EAE1D7;
  }
</style>

