<template>
  <nav class="big d-flex d-lg-flex navbar navbar-light navbar-expand-lg flex-lg-column bg-white static-top" style="">
        <a class="navbar-brand" href="/">
          <img class="d-block d-md-block" src="../assets/logo.svg" height="130px">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsiveBig" aria-controls="navbarResponsiveBig" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsiveBig">
          <NavigationLinks></NavigationLinks>
        </div>
    </nav>
</template>

<script>
import NavigationLinks from '@/components/NavigationLinks'

export default {
  components: {
    NavigationLinks
  },
  computed: {
  },
  mounted() {
    
  }
}
</script>