import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:id?',
    name: 'accueil',
    component: Home,
    props: true
  },
  { path: "*", beforeEnter() {window.location.href = 'https://www.macinstore.fr'} }
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: "",
  linkExactActiveClass: "active",
  base: process.env.NODE_ENV === 'production' ? "/" : "/",
  routes
})

export default router