<template>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item mx-5">
      <a href="https://www.macinstore.fr" class="nav-link text-nowrap">Accueil</a>
    </li>
    <li class="nav-item mx-5">
      <a href="https://www.macinstore.fr/reparations" class="nav-link text-nowrap">Réparations</a>
    </li>
    <li class="nav-item mx-5">
      <a href="https://www.macinstore.fr/qui" class="nav-link text-nowrap">Qui sommes nous ?</a>
    </li>
    <li class="nav-item mx-5">
      <a href="https://www.macinstore.fr/contact" class="nav-link text-nowrap">Contact</a>
    </li>
  </ul>
</template>

<script>
export default {
  methods: {  
  }
}
</script>

<style>
a.nav-link {
  text-transform: uppercase;
  color: black;
  line-height: 30px;
}
a.nav-link span:hover {
  border-bottom: 2px solid #EAE1D7;
  display: inline-block;
}
</style>